<template>
    <div class="mainWrapper pb-0 welcomeCstm">
        <section class="blockElement space bg-white pb-custom16">
            <div class="container max-970">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <h1 class="bold mb-md-3">{{$t('welcomeB.welcomeText1')}}</h1>
                        <p>{{$t('welcomeB.welcomeText2')}}</p>
                        <div class="dualButton text-center my-5">
                            <a :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn me-3">{{$t('welcomeB.welcomeText3')}}</a>
                            <a :href="static_vars.domainURL+'register/'" class="button  zulu_btn border-button rounded">{{$t('welcomeB.welcomeText4')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space upPadding pt-0">
            <div class="container max-970">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="video-container position-relative">
                            <video class="zulu-video" autoplay="autoplay" loop="loop" muted="true" controls="controls">
                                <source src="/assets/video/zulutrade.mp4" type="video/mp4">
                                <source src="/assets/video/zulutrade.mp4" type="video/ogg">
                            </video>
                            <!-- <div id="video-audio"></div> -->

                        </div>
                    </div>
                </div>
            </div>
        </section>
       
        <section class="blockElement space pt-0 why-join-mob">
            <div class="container">
                    <div class="description-container box-shadow mx-auto mb-3">
                            <div class="description-strength"></div>
                            <div class="row align-items-center w-75 mx-auto">
                                <div class="col-12 mb-4 mb-md-5 text-center">
                                    <h2 class="title f-20 line-bottom">{{$t('welcomeB.welcomeText5')}}</h2>
                                </div>

                                <div class="col-12 col-md-12 d-flex mb-2">

                                    <span class="icon-custom-diagonal icon-d-one me-1"></span>
                                    <div class="title medium w-90">
                                        <p class="title medium text-center" v-html="$t('welcomeB.welcomeText6')"></p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-flex mb-2">

                                    <span class="icon-custom-diagonal icon-d-two me-1"></span>
                                    <div class="title medium w-90">
                                        <p class="title medium text-center">{{$t('welcomeB.welcomeText7')}}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-flex mb-2">

                                    <span class="icon-custom-diagonal icon-d-three me-1"></span>
                                    <div class="title medium w-90">
                                        <p class="title medium text-center">{{$t('welcomeB.welcomeText8')}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="description-guard">
                                <span class="icon"></span>
                            </div>

                            <div class="row align-items-center w-75 mx-auto">
                                <div class="col-12 mb-4 mb-md-5 text-center">
                                    <h2 class="title f-20 line-bottom">{{$t('welcomeB.welcomeText9')}}</h2>
                                </div>

                                <div class="col-12 col-md-12 d-flex mb-2">

                                    <span class="icon-custom-diagonal icon-discover-diagonal me-1"></span>
                                    <div class="title medium w-90">
                                        <p class="title medium text-center">{{$t('welcomeB.welcomeText10')}}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-flex mb-2">
                                    <span class="icon-custom-diagonal icon-participate-diagonal me-1"></span>
                                    <div class="title medium w-90">
                                        <p class="title medium text-center">{{$t('welcomeB.welcomeText11')}}</p>
                                    </div>
                                </div>
                                <div class="col-16 col-md-12 d-flex mb-2">
                                    <span class="icon-custom-diagonal icon-experience-diagonal me-1"></span>

                                    <div class="title medium w-90">
                                        <p class="title medium text-center">{{$t('welcomeB.welcomeText12')}}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-flex mb-2">
                                    <span class="icon-custom-diagonal icon-learn-diagonal me-1"></span>

                                    <div class="title medium w-90">
                                        <p class="title medium text-center">{{$t('welcomeB.welcomeText13')}}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-flex mb-2">
                                    <span class="icon-custom-diagonal icon-support-diagonal me-1"></span>

                                    <div class="title medium w-90">
                                        <p class="title medium text-center" v-html="$t('welcomeB.welcomeText14')"></p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-flex mb-2">
                                    <span class="icon-custom-diagonal icon-global-diagonal me-1"></span>
                                    <div class="title medium w-90">
                                        <p class="title medium text-center" v-html="$t('welcomeB.welcomeText15')"></p>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="dualButton text-center my-5">
                        <a :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn me-3">{{$t('welcomeB.welcomeText3')}}</a>
                        <a :href="static_vars.domainURL+'register/'" class="button  zulu_btn border-button rounded bg-white">{{$t('welcomeB.welcomeText4')}}</a>
                    </div>
            </div>
        </section>
    </div>
</template>